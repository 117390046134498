import React from "react";

import styles from "./Details.module.css";

export default function Details() {
  return (
    <>
      <div>
        <h1 className={styles.h1}>Doaa Albadri</h1>
      </div>
    </>
  );
}
